


























  import { computed, defineComponent } from 'vue'

  interface OneCmsArticleProps {
    imageUrl: string
    url: string
    description: string
    title: string
    publicationDate: string
  }

  export default defineComponent({
    name: 'OneCmsArticle',
    props: {
      imageUrl: {
        type: String,
        default: '',
      },
      url: {
        type: String,
        default: '',
      },
      description: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      publicationDate: {
        type: String,
        default: '',
      },
    },
    setup(props: OneCmsArticleProps) {
      const blogUrl = computed(() => `/blog${props.url}`)
      return {
        blogUrl,
      }
    },
  })
